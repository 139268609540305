import { useEffect, useState } from "react";
import { sharedEmitter } from "@demo/shared-resources";
import "./index.css";

export default function Root(props) {
  const [name, setName] = useState("");
  const [points, setPoints] = useState(0);

  const updatePoints = () => {
    console.log("updatePoints", { points });
    sharedEmitter.emit("updatePoints", points);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("name", { get: urlParams.get("name"), window: window });
    setName(urlParams.get("name"));
  }, []);

  return (
    <section>
      Nombre por Query: <strong>{name}</strong>
      <div>
        <input
          type="text"
          className="ml-2 custom-input"
          onChange={(event) => setPoints(event.target.value)}
        />
        <button
          className={`custom-button mt-4 ml-2`}
          onClick={() => updatePoints()}
        >
          Enviar
        </button>
      </div>
    </section>
  );
}
